import axios from "./Axios";
import Api from "./Api";
import { Answer, StaffQuestionnaire } from "pages/questionnaire/typings";
import { AxiosResponse } from "axios";

export default class QuestionnaireApi {
  
    // Gets the questionnaire with the given uuid link
    async getQuestionnaire(uuid: string) {
        const response = await axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/questionnaire/${uuid}`,
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response as AxiosResponse<StaffQuestionnaire>;
    }

    async submitQuestionnaire(uuid: string, answers: Answer[]) {
        const response = await axios({
            method: "POST",
            timeout: Api.Timeout,
            url: `${Api.Host}/questionnaire/${uuid}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                answers,
            },
        });

        return response as AxiosResponse<void>;

    }
}
