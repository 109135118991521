import React from 'react';
import App from 'pages/App';
import useWindowWidth from 'components/hooks/useWindowWidth';
import { useParams } from 'react-router';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import Page from 'components/Page';
import useQuestionnaire from './hooks/useQuestionnaire';
import { Answer, Answers, QUESTIONNAIRE_TYPE, QUESTION_TYPE } from './typings';
import DesktopView from './desktop/View';
import MobileView from './mobile/View';
import { Typography } from '@mui/material';

interface PageParams {
    uuid: string;
}

export default function View() {
    useDocumentTitle('Svara formulär');
    const { isMobile } = useWindowWidth();
    const { uuid } = useParams<PageParams>();
    const { questionnaire, staff, isSubmitted, loading, error,fetchError, submit, resetError } =
        useQuestionnaire({ uuid });
    const [answers, setAnswers] = React.useState<Answers>([]);
    const [sectionIndex, setSectionIndex] = React.useState<number>(0);

    const handleAnswerChange = (
        id: number,
        updateFunc: (prev: Answer) => Answer
    ) => {
        setAnswers(prev => ({
            ...prev,
            [id]: updateFunc(
                prev[id] || { questionID: id, answer: undefined, comment: '' } // uses undefined as default value so we can check if it has been answered
            ),
        }));
    };

    const handleNextSection = () => {
        window.scrollTo({ top: 0});
        setSectionIndex(prev => {
            if (!questionnaire) {
                return prev;
            }
            if (prev < questionnaire.sections.length - 1) {
                return prev + 1;
            }
            return prev;
        });
    };

    const handlePreviousSection = () => {
        setSectionIndex(prev => {
            if (prev > 0) {
                return prev - 1;
            }
            return prev;
        });
    };

    const handleSubmit = () => {
        submit(answers);
    }

    const description = React.useMemo(() => {
        if (!questionnaire) return '';

        if (questionnaire.type === QUESTIONNAIRE_TYPE.HEALTH_DECLARATION) {
            if (isSubmitted) {
                return 'Din hälsodeklaration är inskickad. Ett e-mail har skickats till dig med en kopia av dina svar. Du kan nu stänga sidan.';
            }
            return 'Nu är det dags att fylla i din hälsodeklaration, svara på frågorna i formuläret nedan. När du är klar skickas en kopia till din e-postadress.';
        }

        return '';
    }, [questionnaire?.type, isSubmitted]);

    const section = questionnaire?.sections[sectionIndex];
    const isSectionValid = React.useMemo(() => {
        if (!section) return false;
        return section.questions.every(
            q =>
                q.type !== QUESTION_TYPE.BOOLEAN ||
                answers[q.id]?.answer !== undefined
        );
    }, [section, answers]);
    const isLastSection =
        sectionIndex === (questionnaire?.sections.length || 0) - 1;

        const renderContent = () => isMobile ? (
            <MobileView
                questionnaire={questionnaire}
                staff={staff}
                answers={answers}
                description={description}
                section={section}
                isSectionValid={isSectionValid}
                isLastSection={isLastSection}
                sectionIndex={sectionIndex}
                onNextSection={handleNextSection}
                onPreviousSection={handlePreviousSection}
                isSubmitted={isSubmitted}
                loading={loading}
                error={error}
                onChange={handleAnswerChange}
                onSubmit={handleSubmit}
                onResetError={resetError}
            />
        ) : (
            <DesktopView
                questionnaire={questionnaire}
                staff={staff}
                answers={answers}
                description={description}
                section={section}
                isSectionValid={isSectionValid}
                isLastSection={isLastSection}
                sectionIndex={sectionIndex}
                onNextSection={handleNextSection}
                onPreviousSection={handlePreviousSection}
                isSubmitted={isSubmitted}
                loading={loading}
                error={error}
                onChange={handleAnswerChange}
                onSubmit={handleSubmit}
                onResetError={resetError}
            />
        )

    return (
        <App>
            <Page>
            {fetchError ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '16px',
                        marginTop: '64px',
                        marginLeft: '32px',
                        marginRight: '32px',
                    }}>
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Länken är ogiltig, testa öppna den igen eller kontakta support för en ny.
                    </Typography>
                </div>
            ) : (
                renderContent()
            )}
            </Page>
        </App>
    );
}
