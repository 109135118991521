import React from 'react';
import {
    Answer,
    Answers,
    QUESTION_TYPE,
    Question,
    Questionnaire,
    Section,
    Staff,
} from '../typings';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Button from 'components/interface/Button/Button';
import TextField from 'components/interface/textField/TextField';
import MultipleChoicesOption from '../MultipleChoicesOption';
import BoolenOption from '../BooleanOption';
import CloseTabIcon from '@mui/icons-material/ExitToAppOutlined';
import useMeStore from 'store/useMeStore';
import { useHistory } from 'react-router';

const useStyles = makeStyles<void>()((theme, _params, _classes) => ({
    base: {
        maxWidth: '850px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.spacing(4),
    },
    descriptionText: {
        paddingTop: theme.spacing(2),
    },
    sections: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        rowGap: theme.spacing(2),
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: theme.spacing(2),
    },
    sectionHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: theme.spacing(2),
    },
    sectionHeaderText: {
        background: '#689999',
        width: '60%',
        color: 'white',
        textAlign: 'center',
        fontWeight: 700,
        textTransform: 'uppercase',
        userSelect: 'none',
    },
    sectionHeaderAnswerText: {
        background: '#689999',
        width: '40%',
        color: 'white',
        textAlign: 'right',
        paddingRight: theme.spacing(4),
        fontWeight: 700,
        textTransform: 'uppercase',
        userSelect: 'none',
    },
    question: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: theme.spacing(2),
    },
    questionText: {
        width: '60%',
        textAlign: 'left',
        userSelect: 'none',
        padding: theme.spacing(1),
        background: grey[300],
        borderRadius: theme.shape.borderRadius,
    },
    answer: {
        width: '40%',
        textAlign: 'left',
        userSelect: 'none',
        padding: theme.spacing(1),
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
}));

interface DesktopViewProps {
    questionnaire: Questionnaire | null;
    staff: Staff | null;
    section: Section | undefined;
    isSubmitted: boolean;
    sectionIndex: number;
    answers: Answers;
    description: string;
    isSectionValid: boolean;
    isLastSection: boolean;
    loading: boolean;
    error: boolean;
    onChange: (id: number, updateFunc: (prev: Answer) => Answer) => void;
    onNextSection: () => void;
    onPreviousSection: () => void;
    onSubmit: () => void;
    onResetError: () => void;
}

export default function DesktopView({
    questionnaire,
    staff,
    section,
    isSubmitted,
    sectionIndex,
    answers,
    description,
    isSectionValid,
    isLastSection,
    loading,
    error,
    onChange,
    onNextSection,
    onPreviousSection,
    onSubmit,
    onResetError,
}: DesktopViewProps) {
    const { classes, cx } = useStyles();
    const isLoggedIn = useMeStore(state => state.isLoggedIn);
    const history = useHistory();

    return (
        <div className={classes.base}>
            <div className={classes.description}>
                <Typography
                    variant='h4'
                    fontFamily='Poppins'
                    color='textPrimary'>
                    <b>{questionnaire?.name}</b>
                </Typography>
                <Typography
                    variant='body1'
                    color='textPrimary'
                    fontFamily='Poppins'
                    className={classes.descriptionText}>
                    Hej, {staff?.fullName}! <br /> {description}
                </Typography>
            </div>
            {section && !error && !isSubmitted && (
                <div className={classes.sections}>
                    {sectionIndex === 0 && (
                        <div className={classes.section}>
                            <div
                                className={cx(
                                    classes.sectionHeader,
                                    classes.fullWidth
                                )}>
                                <Typography
                                    variant='h6'
                                    fontFamily='Poppins'
                                    color='textPrimary'
                                    className={cx(
                                        classes.sectionHeaderText,
                                        classes.fullWidth
                                    )}>
                                    Instruktioner
                                </Typography>
                            </div>
                            <Typography
                                variant='body1'
                                fontFamily='Poppins'
                                color='textPrimary'>
                                {questionnaire?.description}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.section}>
                        <div className={classes.sectionHeader}>
                            <Typography
                                variant='h6'
                                fontFamily='Poppins'
                                color='textPrimary'
                                className={classes.sectionHeaderText}>
                                {`${sectionIndex + 1}.${section.title}`}
                            </Typography>
                            <Typography
                                variant='h6'
                                fontFamily='Poppins'
                                color='textPrimary'
                                className={classes.sectionHeaderAnswerText}>
                                Svar:
                            </Typography>
                        </div>
                        {section.questions.map(question => (
                            <QuestionRow
                                key={question.id}
                                question={question}
                                answer={
                                    answers[question.id] || {
                                        questionID: question.id,
                                        answer: '',
                                        comment: '',
                                    }
                                }
                                onChange={updateFunc =>
                                    onChange(question.id, updateFunc)
                                }
                            />
                        ))}
                    </div>
                    <div className={classes.buttons}>
                        <Typography
                            variant='caption'
                            color='textSecondary'
                            fontFamily='Poppins'
                            style={{ userSelect: 'none' }}>
                            Sida {sectionIndex + 1} av{' '}
                            {questionnaire?.sections.length}
                        </Typography>
                        <Button
                            onClick={onPreviousSection}
                            disabled={sectionIndex === 0 || loading}>
                            Föregående
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={isLastSection ? onSubmit : onNextSection}
                            disabled={!isSectionValid}
                            loading={loading}>
                            {isLastSection ? 'Skicka in' : 'Nästa'}
                        </Button>
                    </div>
                </div>
            )}
            {error && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '16px',
                        marginTop: '16px',
                    }}>
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Något gick fel, försök igen. Om problemet kvarstår,
                        kontakta support.
                    </Typography>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onResetError}>
                        Försök igen
                    </Button>
                </div>
            )}
            {isSubmitted && isLoggedIn && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '16px',
                        marginTop: '16px',
                    }}>
                    <Button
                        variant='outlined'
                        color='default'
                        onClick={() => {
                            history.replace('/');
                        }}
                        startIcon={<CloseTabIcon />}>
                        Tillbaka till startsidan
                    </Button>
                </div>
            )}
        </div>
    );
}

interface QuestionRowProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

const QuestionRow = ({ question, answer, onChange }: QuestionRowProps) => {
    const { classes, cx } = useStyles();

    const renderOptions = () => {
        if (question.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
            return (
                <MultipleChoicesOption
                    question={question}
                    answer={answer}
                    onChange={onChange}
                />
            );
        } else if (question.type === QUESTION_TYPE.BOOLEAN) {
            return (
                <BoolenOption
                    question={question}
                    answer={answer}
                    onChange={onChange}
                />
            );
        } else if (question.type === QUESTION_TYPE.TEXT) {
            return (
                <TextField
                    value={answer.answer as string}
                    onChange={v =>
                        onChange(prev => ({
                            ...prev,
                            answer: v,
                        }))
                    }
                    placeholder='Skriv ditt svar här'
                    multiline
                    maxRows={10}
                    minRows={4}
                    fullWidth
                />
            );
        }
    };

    return (
        <div className={classes.question}>
            {question.type !== QUESTION_TYPE.TEXT && (
                <Typography
                    variant='body1'
                    color='textPrimary'
                    fontFamily='Poppins'
                    className={classes.questionText}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: question.question,
                        }}
                    />
                </Typography>
            )}
            <div
                className={cx(classes.answer, {
                    [classes.fullWidth]: question.type === QUESTION_TYPE.TEXT,
                })}>
                {renderOptions()}
            </div>
        </div>
    );
};
