import { Typography } from '@mui/material';
import React from 'react';
import { Answer, Question } from './typings';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';
import TextField from 'components/interface/textField/TextField';

const useStyles = makeStyles<void>()((theme, _params, _classes) => ({
    booleanOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
    booleanOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
    multipleChoiceOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: theme.spacing(1),
    },
    multipleChoiceOptionButton: {
        'padding': theme.spacing(1),
        'minHeight': '32px',
        'minWidth': '32px',
        'maxHeight': '32px',
        'maxWidth': '32px',
        'borderRadius': 16,
        'cursor': 'pointer',
        'backgroundColor': grey[300],
        'transition': 'background-color 0.2s',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'fontSize': '32px',
        'fontWeight': 700,
        '&:hover': {
            backgroundColor: grey[400],
        },
    },
}));

interface BooleanOptionProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

export default function BoolenOption({
    question,
    answer,
    onChange,
}: BooleanOptionProps) {
    const { classes } = useStyles();

    return (
        <div className={classes.booleanOption}>
            {question.allowComment && (
                <TextField
                    value={answer.comment}
                    onChange={v =>
                        onChange(prev => ({
                            ...prev,
                            comment: v,
                        }))
                    }
                    placeholder='Kommentar'
                    multiline
                />
            )}
            <div className={classes.booleanOptions}>
                <div
                    className={classes.multipleChoiceOption}
                    onClick={() =>
                        onChange(prev => ({
                            ...prev,
                            answer: true,
                        }))
                    }>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Ja
                    </Typography>
                    <div className={classes.multipleChoiceOptionButton}>
                        {answer.answer === true ? '✓' : ''}
                    </div>
                </div>
                <div className={classes.multipleChoiceOption}>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Nej
                    </Typography>
                    <div
                        className={classes.multipleChoiceOptionButton}
                        onClick={() =>
                            onChange(prev => ({
                                ...prev,
                                answer: false,
                            }))
                        }>
                        {answer.answer === false ? '✓' : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}
