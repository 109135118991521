import { Typography } from '@mui/material';
import React from 'react';
import { Answer, Question } from './typings';
import { grey } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<void>()((theme, _params, _classes) => ({
    answer: {
        width: '40%',
        textAlign: 'left',
        userSelect: 'none',
        padding: theme.spacing(1),
    },
    multipleChoices: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gap: theme.spacing(4),
    },
    multipleChoiceOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: theme.spacing(1),
    },
    multipleChoiceOptionButton: {
        'padding': theme.spacing(1),
        'minHeight': '32px',
        'minWidth': '32px',
        'maxHeight': '32px',
        'maxWidth': '32px',
        'borderRadius': 16,
        'cursor': 'pointer',
        'backgroundColor': grey[300],
        'transition': 'background-color 0.2s',
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'fontSize': '32px',
        'fontWeight': 700,
        '&:hover': {
            backgroundColor: grey[400],
        },
    },
}));

interface MultipleChoicesOptionProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

export default function MultipleChoicesOption({
    question,
    answer,
    onChange,
}: MultipleChoicesOptionProps) {
    const { classes } = useStyles();

    const a = answer.answer as string[];
    return (
        <div className={classes.multipleChoices}>
            {question.options.map(option => (
                <div key={option} className={classes.multipleChoiceOption}>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        {option}
                    </Typography>
                    <div
                        className={classes.multipleChoiceOptionButton}
                        onClick={() =>
                            onChange(prev => {
                                const a = prev.answer as string[] || [];
                                return {
                                    ...prev,
                                    answer: a.includes(option)
                                        ? a.filter(o => o !== option)
                                        : [...a, option],
                                };
                            })
                        }>
                        {a.includes(option) ? '✓' : ''}
                    </div>
                </div>
            ))}
        </div>
    );
}
