export enum QUESTIONNAIRE_TYPE {
    HEALTH_DECLARATION = 'health_declaration',
}

export interface Questionnaire {
    id: number;
    versionID: number;
    name: string;
    description: string;
    type: QUESTIONNAIRE_TYPE;
    createdOn: Date;
    sections: Section[];
}

export interface Section {
    title: string;
    questions: Question[];
}

export enum QUESTION_TYPE {
    TEXT = 'text',
    BOOLEAN = 'boolean',
    MULTIPLE_CHOICE = 'multiple_choice',
}

export interface Question {
    id: number;
    type: QUESTION_TYPE;
    question: string;
    options: string[]; // Only used for QuestionTypeMultipleChoice
    allowComment: boolean;
}

export interface StaffQuestionnaire {
    questionnaire: Questionnaire;
    isSubmitted: boolean;
    staff: Staff;
}

export interface Staff {
    fullName: string;
}

export type Answers = Record<number, Answer>;

export function answersToArray(answers: Answers): Answer[] {
    return Object.values(answers);
}

export interface Answer {
    questionID: number;
    answer: string | boolean | string[];
    comment: string;
}
